import React, { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import cx from "classnames";

import { Box, Typography, makeStyles, Link, Button } from "@material-ui/core";

import introMP4 from "../../assets/backgrounds/new-intro.mp4";
// import scrollama from "scrollama";

import bgHunter from "../../assets/backgrounds/hunter.png";
import iconController from "../../assets/icons/controller.svg";
import bgIntroduction from "../../assets/backgrounds/intro.png";
import bgIntroductionMobile from "../../assets/backgrounds/mobile/intro.png";
import bgChara from "../../assets/backgrounds/chara.png";
import bgCharaMobile from "../../assets/backgrounds/mobile/chara-mobile.png";
import bgHunterProgram from "../../assets/backgrounds/backgroundGrisWithBlue.png";
import bgEcosystem from "../../assets/backgrounds/backgroundGrisWithBlue.png";

import anemoria from "../../assets/hunterProgram/anemoria.png";
import jotaCorpMessage from "../../assets/hunterProgram/jotaCorpMessage.png";
import jotaCorpMessageMobile from "../../assets/hunterProgram/mobile/jotaCorpMessage.png";
import jotaText from "../../assets/hunterProgram/jotaText.png";

import chara from "../../assets/ecosystemHome/chara.png";
import logoHQ from "../../assets/logoHq.png";
import logoRTG from "../../assets/logoRtg.png";

import titleOurLands from "../../assets/titles/titre-land.png";
import titleAvatars from "../../assets/titles/titre-avatars.png";
import titleGameAsset from "../../assets/titles/titre-game-asset.png";
import titleTeam from "../../assets/titles/titre-team.png";
import titlePartners from "../../assets/titles/titre-partners.png";
import titleHunterProgram from "../../assets/titles/title-hunter-program.png";
import titleEcosystem from "../../assets/titles/title-ecosystem.png";

import bgWrapperResource from "../../assets/resources/wrapperResource.svg";
import bgWrapperResourceSelected from "../../assets/resources/wrapperResourceSelected.svg";
import bgWeaponResourceDetails from "../../assets/resources/weaponseul.png";
import iconTwitter from "../../assets/social/twitter.svg";
import iconDiscord from "../../assets/social/discord.svg";
import iconEcosystem from "../../assets/icons/ecosystem.svg";

import logoUniswap from "../../assets/exchanges/uniswap.png";
import logoMexc from "../../assets/exchanges/mexc.png";

import iconOpensea from "../../assets/social/opensea.svg";

import imgHomeSeo from "../../assets/seo/home.jpg";

import NavBar from "../organisms/NavBar/nav-bar";
import ButtonPerso from "../atoms/ButtonPerso/button-perso";
import Menu from "../organisms/Menu/menu";
import AccordionGameMode from "../molecules/AccordionGameMode/accordion-game-mode";
import BoxChara from "../molecules/BoxChara/box-chara";
import ReadMore from "../atoms/ReadMore/read-more";
import ButtonWithIcon from "../molecules/ButtonWithIcon/button-with-icon";
import ModalCenterTeam from "../molecules/ModalCenterTeam/modal-center-team";

import {
  textBoxCharas,
  textEcosystem,
  textGameModes,
  textHome,
  textModalRegister,
} from "../../constants/text";
import {
  imgLands,
  imgPartners,
  imgWeaponsResources,
  imgWithTextEconomyAssets,
  travellingImages,
} from "../../constants/image";
import { timeToAppear } from "../../constants/animation";
import useWindowDimension from "../../hooks/use-window-dimension";
import { sizeMobileForOurLands } from "../../constants/size";
import DetailsOurLands from "../organisms/DetailsOurLands/details-our-lands";
import ModalCenter from "../atoms/ModalCenter/modal-center";
import { useHistory } from "react-router-dom";
import ButtonWhishlist from "../molecules/ButtonWhishlist/button-whishlist";
import BlueBox from "../atoms/BlueBox/blue-box";
import { url_mexc, url_uniswap } from "../../constants/url";

const preloadImage = (src) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

export default function Home() {
  const classes = useStyle({});
  const history = useHistory();
  const [desactiveWaiting, setDesactivateWaiting] = React.useState(false);
  const [videoIntroLoaded, setVideoIntroLoaded] = React.useState(false); // TODO PUT FALSE
  const [memberSelected, setMemberSelected] = React.useState({});
  const [openModalMember, setOpenModalMember] = React.useState(false);
  const [openModalVideo, setOpenModalVideo] = React.useState(false);
  const [weaponRessourceSelected, setWeaponRessourceSelected] = React.useState(
    imgWeaponsResources[0]
  );
  const [landSelected, setLandSelected] = React.useState(imgLands[1]);
  const [lands, setLands] = React.useState(imgLands);

  const [titleVideoModal, setTitleVideoModal] = React.useState(
    "Oxya Origin Trailer : The Birth of Kryo | Play &amp; Earn"
  );
  const [urlVideoModal, setVideoModal] = React.useState(
    "https://www.youtube.com/embed/zU2sUm8eUqw"
  );

  const { width } = useWindowDimension();
  // const [activeId, setActiveId] = React.useState("");
  // const scroller = scrollama();

  // Ajoutez ces états ensemble
  const [currentTravellingIndex, setCurrentTravellingIndex] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [fadeText, setFadeText] = useState(true);
  const [currentText, setCurrentText] = useState(
    textHome.rtgDetails.loreTexts[0]
  );

  // Préchargement des images
  useEffect(() => {
    const loadImages = async () => {
      try {
        await Promise.all(travellingImages.map((src) => preloadImage(src)));
        setImagesLoaded(true);
      } catch (error) {
        console.error("Erreur lors du préchargement des images:", error);
        setImagesLoaded(true);
      }
    };

    loadImages();
  }, []);

  // Gestion de la transition automatique
  useEffect(() => {
    if (!imagesLoaded) return;

    const interval = setInterval(() => {
      setIsTransitioning(true);
      setTimeout(() => {
        setCurrentTravellingIndex(
          (prev) => (prev + 1) % travellingImages.length
        );
        setIsTransitioning(false);
      }, 300); // Durée du fade out
    }, 4000); // Intervalle entre chaque changement

    return () => clearInterval(interval);
  }, [imagesLoaded]);

  // Style pour le background avec transition
  const travellingBackgroundStyle = {
    backgroundImage: `url(${travellingImages[currentTravellingIndex]})`,
    opacity: !imagesLoaded ? 0 : isTransitioning ? 0 : 1,
  };

  const handleOpenQuest = () => {
    window.open("https://quest.oxyaorigin.com", "_blank");
  };

  const isRegistered = useMemo(
    () => history.location?.state?.modalRegister,
    [history.location.state]
  );

  const handleCloseModal = () => {
    setOpenModalMember(false);
    setOpenModalVideo(false);
    setMemberSelected({});
    if (history.location.state?.modalRegister) {
      history.replace();
    }
  };

  const handleAccountPage = () => {
    history.push("/account");
  };

  const handleClickBuyAvatar = () => {
    window.open("https://opensea.io/collection/oxyaoriginproject", "_blank");
  };

  const handleClickPartner = () => {
    window.open("https://forms.gle/doeBucZTK9w1Xa5k9", "_blank");
  };

  const handleClickStartHunting = () => {
    window.open("https://quest.oxyaorigin.com", "_blank");
  };

  const handleClickLearnMore = () => {
    window.open("https://discord.com/invite/oxyaorigin", "_blank");
  };

  const handleClickLand = (item) => () => {
    const itemsClasses = Array.from(
      document.getElementsByClassName(classes.btnImgLand)
    );

    const tmpLands = lands;

    for (const itemClass of itemsClasses) {
      itemClass.classList.remove(classes.btnImgLandSelected);
    }

    const oldItemSelected = landSelected;
    setLandSelected(item);

    const itemSelectedDom = document.getElementById(item.id);
    itemSelectedDom.classList.add(classes.btnImgLandSelected);

    const oldItemSelectedDom = document.getElementById(oldItemSelected.id);
    const noSelectedDom = document.getElementById(
      lands.filter(
        (land) => land.id !== oldItemSelected.id && land.id !== item.id
      )[0].id
    );

    let offsetSelected = 0;
    let offsetOldSelected = 0;

    if (item.key === oldItemSelected.key) return;

    if (
      // Si kryo est au milieu et naya à droite et on veut aller vers naya
      lands[item.key].step === 3 &&
      lands[oldItemSelected.key].step === 2 &&
      lands[item.key].fakeStep !== 1 // Naya n'est pas à gauche
    ) {
      // console.log("change kryo to naya in right");
      offsetSelected = -150;
      offsetOldSelected = 230;
      tmpLands[item.key].fakeStep = 2;
      tmpLands[oldItemSelected.key].fakeStep = 3;
    }

    if (
      // Si kryo est à droite et naya au milieu et on veut aller vers kryo
      lands[item.key].step === 2 &&
      lands[oldItemSelected.key].step === 3 &&
      lands[item.key].fakeStep !== 1 // Kryo n'est pas à gauche
    ) {
      // console.log("change naya to kryo in right");

      tmpLands[item.key].fakeStep = 3;
      tmpLands[oldItemSelected.key].fakeStep = 2;
    }

    if (
      lands[item.key].step === 1 &&
      lands[oldItemSelected.key].step === 2 &&
      lands[item.key].fakeStep !== 3
    ) {
      // console.log("change kryo to luxion in left");

      offsetSelected = 150;
      offsetOldSelected = -230;
      tmpLands[item.key].fakeStep = 2;
      tmpLands[oldItemSelected.key].fakeStep = 1;
    }

    if (
      lands[item.key].step === 2 &&
      lands[oldItemSelected.key].step === 1 &&
      lands[item.key].fakeStep !== 3
    ) {
      // console.log("change luxion to kryo in left");

      tmpLands[item.key].fakeStep = 2;
      tmpLands[oldItemSelected.key].fakeStep = 1;
    }
    // LUXION ET NAYA BIZARRE VERS LA DROITE
    if (
      // Si luxion a changé et naya est resté le même et on veut aller vers naya
      lands[item.key].fakeStep === 3 &&
      lands[oldItemSelected.key].fakeStep === 2 &&
      lands[item.key].fakeStep === lands[item.key].step &&
      lands[oldItemSelected.key].fakeStep !== lands[oldItemSelected.key].step &&
      lands[item.key].step !== 2
    ) {
      // console.log("change luxion to naya in right");

      offsetSelected = -150;
      offsetOldSelected = 380; // 150 + 230
      tmpLands[item.key].fakeStep = 2;
      tmpLands[oldItemSelected.key].fakeStep = 3;
      noSelectedDom.style.transform = `translateX(-150px)`; // Kryo le remettre bien
    }

    if (
      // Si luxion a changé et naya a changé aussi et on veut aller vers luxion
      lands[item.key].fakeStep === 3 &&
      lands[oldItemSelected.key].fakeStep === 2 &&
      lands[item.key].fakeStep !== lands[item.key].step &&
      lands[oldItemSelected.key].fakeStep !== lands[oldItemSelected.key].step &&
      lands[item.key].step !== 2
    ) {
      // console.log("change naya to luxion in right");

      offsetSelected = 150;
      offsetOldSelected = 0; // 150 + 230
      tmpLands[item.key].fakeStep = 2;
      tmpLands[oldItemSelected.key].fakeStep = 3;
      noSelectedDom.style.transform = `translateX(-230px)`; // Kryo le remettre bien
    }

    // LUXION ET NAYA BIZARRE VERS LA GAUCHE

    if (
      // Si naya a changé et luxion est resté le même
      lands[item.key].fakeStep === 1 &&
      lands[oldItemSelected.key].fakeStep === 2 &&
      lands[item.key].fakeStep === lands[item.key].step &&
      lands[oldItemSelected.key].fakeStep !== lands[oldItemSelected.key].step &&
      lands[item.key].step !== 2
    ) {
      // console.log("change naya to luxion in left");

      offsetSelected = 150;
      offsetOldSelected = -380; // -150 - 230
      tmpLands[item.key].fakeStep = 2;
      tmpLands[oldItemSelected.key].fakeStep = 1;
      noSelectedDom.style.transform = `translateX(150px)`; // Kryo le remettre bien
    }

    if (
      // Si naya a changé et luxion a changé aussi
      lands[item.key].fakeStep === 1 &&
      lands[oldItemSelected.key].fakeStep === 2 &&
      lands[item.key].fakeStep !== lands[item.key].step &&
      lands[oldItemSelected.key].fakeStep !== lands[oldItemSelected.key].step &&
      lands[item.key].step !== 2
    ) {
      // console.log("change luxion to naya in left");

      offsetSelected = -150;
      offsetOldSelected = 0;
      tmpLands[item.key].fakeStep = 2;
      tmpLands[oldItemSelected.key].fakeStep = 1;
      noSelectedDom.style.transform = `translateX(230px)`; // Kryo le remettre bien
    }

    if (
      // Si je suis naya et j'veux aller vers kryo à gauche
      lands[item.key].fakeStep === 1 &&
      lands[oldItemSelected.key].fakeStep === 2 &&
      lands[item.key].fakeStep !== lands[item.key].step &&
      lands[oldItemSelected.key].fakeStep === lands[oldItemSelected.key].step &&
      lands[oldItemSelected.key].step === 2
    ) {
      // console.log("change naya to kryo in left");

      offsetSelected = 0;
      offsetOldSelected = -380;
      tmpLands[item.key].fakeStep = 2;
      tmpLands[oldItemSelected.key].fakeStep = 1;
    }
    if (
      // Si je suis kryo et j'veux aller vers naya à gauche
      lands[item.key].fakeStep === 1 &&
      lands[oldItemSelected.key].fakeStep === 2 &&
      lands[item.key].fakeStep !== lands[item.key].step &&
      lands[oldItemSelected.key].fakeStep === lands[oldItemSelected.key].step &&
      lands[oldItemSelected.key].step === 2
    ) {
      // console.log("change kryo to naya in left");

      offsetSelected = -150;
      offsetOldSelected = -150;
      tmpLands[item.key].fakeStep = 2;
      tmpLands[oldItemSelected.key].fakeStep = 1;
    }

    // KRYO ET A LUXION A DROITE SONT BIZARRES

    if (
      // Si luxion a changé (au milieu) et kryo à changer (à droite) et on veut aller kryo
      lands[item.key].fakeStep === 3 &&
      lands[oldItemSelected.key].fakeStep === 2 &&
      lands[item.key].fakeStep !== lands[item.key].step &&
      lands[oldItemSelected.key].fakeStep !== lands[oldItemSelected.key].step &&
      lands[item.key].step === 2 &&
      lands[oldItemSelected.key].step === 1
    ) {
      // console.log("change luxion to kryo in right");

      offsetSelected = 0;
      offsetOldSelected = 380; // 150 + 230
      tmpLands[item.key].fakeStep = 2;
      tmpLands[oldItemSelected.key].fakeStep = 3;
    }

    if (
      // Si luxion a changé (à droite) et kryo est au milieu et on veut aller luxion
      lands[item.key].fakeStep === 3 &&
      lands[oldItemSelected.key].fakeStep === 2 &&
      lands[item.key].fakeStep !== lands[item.key].step &&
      lands[oldItemSelected.key].fakeStep === lands[oldItemSelected.key].step &&
      lands[item.key].step === 1 &&
      lands[oldItemSelected.key].step === 2
    ) {
      // console.log("change luxion to kryo in right");

      offsetSelected = 150;
      offsetOldSelected = 150; // 150 + 230
      tmpLands[item.key].fakeStep = 2;
      tmpLands[oldItemSelected.key].fakeStep = 3;
    }

    itemSelectedDom.style.transform = `translateX(${offsetSelected}px)`;
    oldItemSelectedDom.style.transform = `translateX(${offsetOldSelected}px)`;

    setLands(tmpLands);
  };

  const handleClickBoxWR = (item) => () => {
    const itemsClasses = Array.from(
      document.getElementsByClassName(classes.boxWeaponResource)
    );

    for (const itemClass of itemsClasses) {
      itemClass.classList.remove(classes.boxWeaponResourceSelected);
    }

    const oldItemSelected = weaponRessourceSelected;

    setWeaponRessourceSelected(item);

    const offsetMultiplicator = item.step - oldItemSelected.step;

    console.log(offsetMultiplicator, "offsetMultiplicator");

    const itemSelected = document.getElementById(item.id);
    itemSelected.classList.add(classes.boxWeaponResourceSelected);

    const wrapperCaroussel = document.getElementById("wrapperCarousselWPRS");

    const translate =
      wrapperCaroussel.style.transform.substring(11).split("p")[0] || 0;
    const numberTranslate = parseInt(translate, 10);
    console.log(numberTranslate, "translateRecent");

    let addSomePixel = offsetMultiplicator < 0 ? 100 : -100;

    let offset = 300;

    if (width < 450) {
      addSomePixel = offsetMultiplicator < 0 ? 20 : -20;
      offset = 285;
    }

    let translation =
      offset * offsetMultiplicator +
      (oldItemSelected.step === 1 || item.step === 1 ? addSomePixel : 0) -
      translate;

    if (item.step === 1) {
      translation = 0;
    }

    console.log(item);

    wrapperCaroussel.style.transform = `translateX(-${translation}px)`;
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    /*const handleScroll = () => {
      scroller
        .setup({
          step: "section",
          offset: 0.5,
          progress: true,
        })
        .onStepEnter((response) => {
          setActiveId(response.element.id);
        });
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };*/
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setDesactivateWaiting(true);
    }, [5000]);
  }, []);

  /*useEffect(() => {
    history.push(`#${activeId}`);
    const menu = document.getElementById("menu");
    if (
      activeId === "characters" ||
      activeId === "ecosystem" ||
      activeId === "team"
    ) {
      menu.classList.add(classes.bgMenu);
    } else {
      menu.classList.remove(classes.bgMenu);
    }
  }, [activeId, history, classes.bgMenu]);*/

  useEffect(() => {
    if (videoIntroLoaded || desactiveWaiting) {
      let timeToWait = videoIntroLoaded ? timeToAppear + 200 : 0;
      const timeOut = setTimeout(() => {
        const titleIntro = document.getElementById("titleIntro");
        // const detailsIntro = document.getElementById("detailsIntro");
        const codeIntro = document.getElementById("codeIntro");
        const boxExchanges = document.getElementById("boxExchanges");
        const boxClaim = document.getElementById("boxClaim");
        titleIntro.classList.add(classes.translate0);
        // detailsIntro.classList.add(classes.translate0);
        codeIntro.classList.add(classes.translate0);
        boxExchanges.classList.add(classes.translate0);
        boxClaim.classList.add(classes.translate0);
      }, [timeToAppear]);

      const timeOutBtn = setTimeout(() => {
        const btnRegister = document.getElementById("btnRegister");
        const btnOxyaTrailer = document.getElementById("btnTrailer");
        btnRegister.classList.add(classes.translate0);
        btnOxyaTrailer.classList.add(classes.translate0);
      }, [timeToWait]);

      return () => {
        clearTimeout(timeOut);
        clearTimeout(timeOutBtn);
      };
    }
  }, [videoIntroLoaded, classes.translate0]);

  const vidRef = useRef();

  useEffect(() => {
    vidRef.current.play();
  }, []);

  // Effet pour la transition du texte
  useEffect(() => {
    setFadeText(false);

    const textChangeTimeout = setTimeout(() => {
      setCurrentText(textHome.rtgDetails.loreTexts[currentTravellingIndex]);
      setFadeText(true);
    }, 300);

    return () => clearTimeout(textChangeTimeout);
  }, [currentTravellingIndex]);

  return (
    <>
      <div className={classes.root}>
        <Helmet>
          <title>Oxya Origin</title>
          <meta name="title" content="Oxya Origin" />
          <meta
            name="description"
            content="Oxya Origin is building a powerful gaming ecosystem featuring shooter, exploration and strategy game modes linked by a player-owned asset economy."
          />

          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://oxyaorigin.com/" />
          <meta property="og:title" content="Oxya Origin" />
          <meta
            property="og:description"
            content="Oxya Origin is building a powerful gaming ecosystem featuring shooter, exploration and strategy game modes linked by a player-owned asset economy."
          />
          <meta property="og:image" content={imgHomeSeo} />
          {/* Twitter */}

          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:url" content="https://oxyaorigin.com/" />
          <meta property="twitter:title" content="Oxya Origin" />
          <meta
            property="twitter:description"
            content="Oxya Origin is building a powerful gaming ecosystem featuring shooter, exploration and strategy game modes linked by a player-owned asset economy."
          />
          <meta property="twitter:image" content={imgHomeSeo} />
        </Helmet>
        {openModalMember && (
          <ModalCenterTeam
            open={openModalMember}
            onClose={handleCloseModal}
            member={memberSelected}
          />
        )}
        {openModalVideo && (
          <ModalCenter open={openModalVideo} onClose={handleCloseModal}>
            <div className={classes.iframeWrapper}>
              <iframe
                src={urlVideoModal}
                title={titleVideoModal}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </ModalCenter>
        )}
        {isRegistered && (
          <ModalCenter
            open={isRegistered}
            onClose={handleCloseModal}
            classNameChildren={classes.modalChildrenRegister}
            classNamePaper={classes.modalPaperRegister}
            closeBtn={false}
          >
            <Typography variant="h2" className={classes.titleModalRegister}>
              {textModalRegister.title}
            </Typography>
            <Typography
              variant="body1"
              className={classes.detailsModalRegister}
            >
              {textModalRegister.details}
            </Typography>
            <ul>
              {textModalRegister.listDetails.map((elem) => (
                <li key={`list-details-register-${elem.key}`}>{elem.text}</li>
              ))}
            </ul>
            <Box className={classes.boxSocialRegister}>
              <Link
                style={{ display: "inline-flex" }}
                href="https://discord.com/invite/oxyaorigin"
                target="_blank"
              >
                <img src={iconDiscord} width={20} height={19} alt="discord" />
              </Link>
              <Link
                href="https://twitter.com/OxyaOrigin"
                style={{ display: "inline-flex" }}
                target="_blank"
              >
                <img src={iconTwitter} width={20} height={21} alt="twitter" />
              </Link>
            </Box>
            <Box display="flex" flexDirection="column" gridRowGap={10}>
              <ButtonPerso
                className={classes.btnAccountPage}
                onClick={handleAccountPage}
              >
                My Account
              </ButtonPerso>
              <Button onClick={handleCloseModal}>Skip</Button>
            </Box>
          </ModalCenter>
        )}
        <section className={classes.sectionTop}>
          <video
            onPlay={() => {
              setVideoIntroLoaded(true);
            }}
            playsInline
            autoPlay
            loop
            muted
            ref={vidRef}
          >
            <source type="video/mp4" src={introMP4} />
          </video>
          {videoIntroLoaded === false && desactiveWaiting === false && (
            <div className={classes.wrapperLoading}>
              … Loading …
              <br />
              <div className={classes.ldsEllipsis}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
          <Box className={classes.wrapperTopWithMenu}>
            <Menu className={classes.menu} canBeVisible={videoIntroLoaded} />
            <Box className={classes.wrapperTop}>
              <NavBar canBeVisible={videoIntroLoaded} noWait={false} />
              <Box display="flex" className={classes.contentTop}>
                <Box className={classes.boxTextTop}>
                  <Typography
                    variant="h2"
                    id="titleIntro"
                    className={classes.translateRight}
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {textHome.title}
                  </Typography>
                  {/*<Typography
                    variant="body1"
                    className={cx(classes.text, classes.translateRight)}
                    style={{ whiteSpace: "pre-line" }}
                    id="detailsIntro"
                  >
                    {textHome.details}
                  </Typography>*/}
                  <Typography
                    variant="body1"
                    className={cx(classes.text, classes.translateRight)}
                    style={{
                      whiteSpace: "pre-line",
                      fontSize: "2.4rem",
                      marginTop: "3rem",
                      fontWeight: 600,
                    }}
                    id="codeIntro"
                  >
                    {textHome.code}
                  </Typography>
                </Box>
                <Box
                  id="boxExchanges"
                  className={cx(classes.translateRight, classes.boxExchanges)}
                >
                  <Typography
                    variant="h3"
                    style={{
                      fontSize: "3rem",
                      textTransform: "uppercase",
                      fontWeight: 600,
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Purchase $OXYZ Token at following exchanges
                  </Typography>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    flexWrap="wrap"
                    gridColumnGap="2rem"
                    gridRowGap="2rem"
                  >
                    <button
                      className={classes.btnExchange}
                      onClick={() => {
                        window.open(url_uniswap, "_blank");
                      }}
                    >
                      <img src={logoUniswap} alt="Uniswap" />
                    </button>
                    <button
                      className={classes.btnExchange}
                      onClick={() => {
                        window.open(url_mexc, "_blank");
                      }}
                    >
                      <img src={logoMexc} alt="Mexc" />
                    </button>
                  </Box>
                </Box>
                <Box
                  display="flex"
                  gridRowGap="2rem"
                  gridColumnGap="2rem"
                  justifyContent="center"
                  flexWrap="wrap"
                  alignItems="center"
                  id="boxClaim"
                  className={classes.translateRight}
                >
                  <ButtonPerso
                    className={classes.btnClaim}
                    onClick={() => {
                      window.open("https://claim.oxyaorigin.com", "_blank");
                    }}
                  >
                    Claim Your $OXYZ
                  </ButtonPerso>
                  <ButtonPerso
                    className={classes.btnClaim}
                    onClick={() => {
                      window.open("https://oxyz.oxyaorigin.com", "_blank");
                    }}
                  >
                    Stake Your $OXYZ (IMX zkEVM)
                  </ButtonPerso>
                </Box>
                <button
                  type="button"
                  className={cx(classes.btnTrailer, classes.translateBottom)}
                  onClick={handleOpenQuest}
                  id="btnTrailer"
                >
                  <Box
                    display="flex"
                    gridColumnGap="2rem"
                    gridRowGap="2rem"
                    alignItems="center"
                  >
                    <Box className={classes.wrapperIconPlay}>
                      <img
                        src={iconController}
                        width={50}
                        height={50}
                        alt="register"
                      />
                    </Box>
                    <Box display="flex" flexDirection="column">
                      <Typography
                        variant="h3"
                        style={{ fontSize: "1.4rem", textAlign: "left" }}
                      >
                        In Game Quests
                      </Typography>
                      <Typography
                        variant="h3"
                        style={{
                          fontSize: "2.6rem",
                          textTransform: "uppercase",
                          textAlign: "left",
                        }}
                      >
                        Hunter Program
                      </Typography>
                    </Box>
                  </Box>
                </button>
                <ButtonWhishlist
                  id="btnRegister"
                  className={classes.translateBottom}
                />
              </Box>
            </Box>
          </Box>
        </section>
        <section className={classes.section}>
          <Box className={classes.wrapperIntro}>
            <div className={classes.overlayIntro}></div>
            <Box className={classes.wrapperAccordionGame}>
              {textGameModes.map((textGameMode) => (
                <AccordionGameMode
                  defaultExpanded={textGameMode.defaultExpanded}
                  title={textGameMode.title}
                  details={textGameMode.details}
                  key={textGameMode.key}
                  textBtnActionOne={textGameMode.textBtnActionOne}
                  hrefIconBtnActionOne={textGameMode.hrefIconBtnActionOne}
                  textBtnActionTwo={textGameMode.textBtnActionTwo}
                  hrefIconBtnActionTwo={textGameMode.hrefIconBtnActionTwo}
                  textBtnPlay={textGameMode.textBtnPlay}
                  hrefBackgroundBtn={textGameMode.hrefBackgroundBtn}
                  isDisabledOne={textGameMode.disabledOne}
                  isDisabledTwo={textGameMode.disabledTwo}
                  onClickOne={textGameMode.onClickOne(history)}
                  onClickTwo={textGameMode.onClickTwo(history)}
                  hashLinkOne={textGameMode.hashLinkOne}
                  hashLinkTwo={textGameMode.hashLinkTwo}
                  onClickVideo={textGameMode.onClickVideo(
                    setTitleVideoModal,
                    setVideoModal,
                    setOpenModalVideo
                  )}
                />
              ))}
              <BlueBox
                addDecoration
                className={classes.boxIntroduction}
                top="11%"
                height="30px"
              >
                <Typography
                  variant="h3"
                  className={classes.introductionTitle}
                  style={{
                    color: "white",
                    fontSize: "2rem",
                    fontFamily: "Akira",
                    fontWeight: 600,
                    textTransform: "uppercase",
                  }}
                >
                  News
                </Typography>
                <Typography variant="body1" style={{ textAlign: "left" }}>
                  {textHome.textIntroduction}
                </Typography>
              </BlueBox>
            </Box>
          </Box>
        </section>
        <section className={classes.section} id="rtgDetails">
          <Box className={classes.overlayRTGDetails}>
            <div
              className={classes.travellingBackground}
              style={travellingBackgroundStyle}
            />
            <Box className={classes.wrapperRTGDetails}>
              <Box className={classes.rtgDetailsContent}>
                <BlueBox
                  addDecoration
                  className={classes.boxRTGDetailsProcedural}
                >
                  <Typography variant="h4" className={classes.rtgDetailsTitle}>
                    {textHome.rtgDetails.procedural.title}
                  </Typography>
                </BlueBox>
                <Typography
                  variant="body1"
                  className={cx(classes.rtgDetailsLore, {
                    [classes.fadeIn]: fadeText,
                    [classes.fadeOut]: !fadeText,
                  })}
                >
                  {currentText}
                </Typography>
                <BlueBox
                  addDecoration
                  className={classes.boxRTGDetailsLoot}
                  top="15px"
                  height="55px"
                >
                  <Typography variant="h4" className={classes.rtgDetailsTitle}>
                    {textHome.rtgDetails.loot.title}
                  </Typography>
                  <Typography
                    variant="body1"
                    textAlign="justify"
                    sx={{ mt: 1, lineHeight: 1.2 }}
                  >
                    {textHome.rtgDetails.loot.details}
                  </Typography>
                </BlueBox>
                <ButtonWithIcon
                  className={classes.btnLearnMore}
                  text="Learn More"
                  hrefIcon={iconEcosystem}
                  onClick={handleClickLearnMore}
                />
              </Box>
            </Box>
          </Box>
        </section>
        <section className={classes.section} id="hunterProgram">
          <Box className={classes.wrapperHunterProgram}>
            <Box className={classes.hunterProgramHeader}>
              <img
                src={jotaText}
                alt="Inquire at the nearest JOTA corporation recruitment center"
                className={classes.headerText}
              />
            </Box>
            <Box className={classes.titleAndLogoContainer}>
              <Box className={classes.wrapperTitle}>
                <Box
                  className={cx(classes.bgTitle, classes.bgTitleHunterProgram)}
                >
                  <h1 style={{ marginTop: "-15px" }}>Hunter Program</h1>
                </Box>
              </Box>
              <Box className={classes.triangleLogoContainer}>
                <img
                  src={anemoria}
                  alt="Anemoria Triangle Logo"
                  className={classes.triangleLogo}
                />
              </Box>
            </Box>
            <Box className={classes.hunterProgramContent}>
              <Box className={classes.recruitmentPoster}>
                <img
                  src={jotaCorpMessage}
                  alt="Kryo Needs You Recruitment Poster"
                  className={classes.posterImageDesktop}
                />
                <img
                  src={jotaCorpMessageMobile}
                  alt="Kryo Needs You Recruitment Poster"
                  className={classes.posterImageMobile}
                />
              </Box>
              <ButtonWithIcon
                text="START HUNTING"
                className={classes.startHuntingButton}
                onClick={handleClickStartHunting}
              />
            </Box>
          </Box>
        </section>
        <section className={classes.section} id="ecosystem">
          <Box className={classes.wrapperEcosystem}>
            <Box
              className={cx(
                classes.wrapperTitle,
                classes.wrapperTitleWithoutMarginBottom
              )}
            >
              <Box className={cx(classes.bgTitle, classes.bgTitleEcosystem)}>
                <h1>Ecosystem</h1>
              </Box>
            </Box>
            <Box className={classes.ecosystemContent}>
              {/* Colonne de gauche - RTG */}
              <Box className={classes.ecosystemColumn}>
                <img
                  src={logoRTG}
                  alt="Road to Genesis Logo"
                  className={classes.ecosystemLogo}
                />
                <BlueBox
                  addDecoration
                  className={cx(classes.ecosystemBox, classes.gamingMechanism)}
                  top="10px"
                  height="40px"
                >
                  <Typography
                    variant="h4"
                    className={classes.ecosystemBoxTitle}
                  >
                    {textEcosystem.rtg.title}
                  </Typography>
                  <Typography variant="body1">
                    {textEcosystem.rtg.details}
                  </Typography>
                </BlueBox>
              </Box>

              {/* Personnage central */}
              <img
                src={chara}
                alt="Character"
                className={classes.ecosystemCharacter}
              />

              {/* Colonne de droite - HQ */}
              <Box className={classes.ecosystemColumn}>
                <img
                  src={logoHQ}
                  alt="HQ Logo"
                  className={classes.ecosystemLogo}
                />
                <BlueBox
                  addDecoration
                  className={cx(classes.ecosystemBox, classes.hqMechanism)}
                  top="10px"
                  height="40px"
                >
                  <Typography
                    variant="h4"
                    className={classes.ecosystemBoxTitle}
                  >
                    {textEcosystem.hq.title}
                  </Typography>
                  <Typography variant="body1">
                    {textEcosystem.hq.details}
                  </Typography>
                </BlueBox>
              </Box>
            </Box>
          </Box>
        </section>
        <section className={classes.section} id="gameAssets">
          <Box className={classes.wrapperGameAssets}>
            <Box
              className={cx(
                classes.wrapperTitle,
                classes.wrapperTitleWithoutMarginBottom
              )}
            >
              <Box className={cx(classes.bgTitle, classes.bgTitleGameAsset)}>
                <h1>Game Assets</h1>
              </Box>
            </Box>
            <Box className={classes.wrapperBoxGameAssets}>
              {imgWithTextEconomyAssets.map((asset) => (
                <Box key={asset.key} className={classes.tokenCardWrapper}>
                  <Typography variant="h3" className={classes.tokenTitle}>
                    {asset.title}
                  </Typography>
                  <BlueBox className={classes.tokenCard}>
                    <img src={asset.img} alt={`${asset.title} resource`} />
                    <Typography variant="h4">{asset.subtitle}</Typography>
                    <Typography variant="body1">{asset.details}</Typography>
                  </BlueBox>
                </Box>
              ))}
            </Box>
            <Box className={classes.wrapperWeaponsRessources}>
              <Box
                className={classes.wrapperCarousselWPRS}
                id="wrapperCarousselWPRS"
              >
                {imgWeaponsResources.map((elem) => (
                  <button
                    className={cx(
                      classes.boxWeaponResource,
                      elem.defaultSelected
                        ? classes.boxWeaponResourceSelected
                        : ""
                    )}
                    key={`weapon-resources-${elem.key}`}
                    id={elem.id}
                    type="button"
                    onClick={handleClickBoxWR(elem)}
                  >
                    <img src={elem.img} alt={elem.name} />
                  </button>
                ))}
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                gridRowGap="1rem"
                gridColumnGap="1rem"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="h4">
                  {weaponRessourceSelected.title}
                </Typography>
                <Typography variant="body1">
                  {weaponRessourceSelected.details}
                </Typography>
                <Box
                  className={cx(
                    classes.wrapperImgWPRSelected,
                    weaponRessourceSelected.key === 1 &&
                      classes.wrapperImgResourceRock
                  )}
                >
                  <img
                    alt={weaponRessourceSelected.name}
                    src={weaponRessourceSelected.img}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </section>

        <section className={classes.section} id="avatars">
          <Box className={classes.wrapperChara}>
            <Box className={classes.wrapperTitle}>
              <Box className={cx(classes.bgTitle, classes.bgTitleAvatars)}>
                <h1>Avatars</h1>
              </Box>
            </Box>
            <Box display="flex" justifyContent="center" padding="0 2rem">
              <Typography variant="body1" className={classes.textDetailsLands}>
                "Avatars" are exclusive collectibles that provide players with
                enhanced perks in Road to Genesis (RtG), functioning as a
                superior version of a season pass and enabling owners to earn
                premium currency, OXYA, by completing game objectives. In
                HeadQuarters (HQ), avatars will gain additional functionality,
                with details to be revealed as HQ evolves.
              </Typography>
            </Box>
            <Box className={classes.wrapperImgChara}>
              <img
                src={bgChara}
                alt="background characters"
                className={classes.imgCharaDesktop}
              />
              <img
                src={bgCharaMobile}
                alt="background characters mobile"
                className={classes.imgCharaMobile}
              />
            </Box>
            <Box className={classes.wrapperBoxCharas}>
              {textBoxCharas.map((elem) => (
                <BoxChara
                  className={classes.boxChara}
                  key={elem.key}
                  title={elem.title}
                  color={elem.color}
                  colorTextShadow={elem.colorTextShadow}
                  subTitle={elem.subTitle}
                  hrefPlanet={elem.hrefPlanet}
                >
                  {elem.title === "Niffelheim" ? (
                    <>
                      <ReadMore className={classes.readMoreLuxion}>
                        {elem.details}
                      </ReadMore>
                      <Box className={classes.detailsLuxion}>
                        {elem.details}
                      </Box>
                      <ButtonWithIcon
                        hrefIcon={iconOpensea}
                        text="Buy"
                        className={classes.btnBuyOpensea}
                        onClick={handleClickBuyAvatar}
                      />
                    </>
                  ) : (
                    elem.details
                  )}
                </BoxChara>
              ))}
            </Box>
          </Box>
        </section>
        <section className={classes.section} id="lands">
          <Box className={classes.wrapperLand}>
            <Box className={classes.wrapperTitle}>
              <Box className={cx(classes.bgTitle, classes.bgTitleLands)}>
                <h1>Lands</h1>
              </Box>
            </Box>
            <Box className={classes.wrapperContentLand}>
              <Box display="flex" justifyContent="center" padding="0 2rem">
                <Typography
                  variant="body1"
                  className={classes.textDetailsLands}
                >
                  Lands are fully customisable and the supply chain of the
                  ecosystem. Landowners can extract resources from their Lands
                  and use them to craft and upgrade weapons, buildings and
                  equipment.
                </Typography>
              </Box>
              <Box className={classes.wrapperImgLands}>
                {lands.map((imgLand) => (
                  <button
                    type="button"
                    className={cx(
                      classes.btnImgLand,
                      imgLand.defaultSelected ? classes.btnImgLandSelected : ""
                    )}
                    key={`planet-${imgLand.key}`}
                    style={imgLand.style}
                    id={imgLand.id}
                    onClick={handleClickLand(imgLand)}
                  >
                    <img
                      alt={imgLand.name}
                      src={imgLand.imgPlanet}
                      className={classes.imgLand}
                    />
                  </button>
                ))}
              </Box>
            </Box>
            <DetailsOurLands landSelected={landSelected} />
          </Box>
        </section>
        <section className={classes.section}>
          <Box className={classes.wrapperTitle} style={{ marginBottom: 0 }}>
            <Box className={cx(classes.bgTitle, classes.bgTitlePartners)}>
              {/* <h1 style={{ padding: "0 2rem 3rem 2rem" }}>Our Eco System</h1> */}
              <h1>Partners & Backers</h1>
            </Box>
          </Box>
          <Box className={classes.wrapperPartners}>
            {imgPartners.map((imgPartner) => (
              <img
                src={imgPartner.img}
                alt={`logo ${imgPartner.alt}`}
                key={`${imgPartner.key}-${imgPartner.alt}`}
                style={{ width: imgPartner.alt === "Blck Paris" && "130px" }}
              />
            ))}
          </Box>
          <ButtonPerso
            className={cx(classes.btnRegular, classes.mAuto)}
            onClick={handleClickPartner}
          >
            Become a partner
          </ButtonPerso>
        </section>
      </div>
    </>
  );
}

const useStyle = makeStyles(({ breakpoints, palette, spacing }) => ({
  "@keyframes lds-ellipsis1": {
    "0%": {
      transform: "scale(0)",
    },
    "100%": {
      transform: "scale(1)",
    },
  },
  "@keyframes lds-ellipsis2": {
    "0%": {
      transform: "translate(0, 0)",
    },
    "100%": {
      transform: "translate(24px, 0)",
    },
  },
  "@keyframes lds-ellipsis3": {
    "0%": {
      transform: "scale(1)",
    },
    "100%": {
      transform: "scale(0)",
    },
  },
  translateRight: {
    opacity: 0,
    transform: "translateX(100px)",
    transition: "0.3s all ease-in-out",
  },
  translateBottom: {
    opacity: 0,
    transform: "translateY(50px)",
    transition: "0.3s all ease-in-out",
  },
  translate0: {
    opacity: 1,
    transform: "translate(0)",
  },
  wrapperLoading: {
    zIndex: 99999,
    margin: 0,
    overflow: "hidden",
    background: palette.primary.main,
    transition: "all ease-out .5s",
    fontSize: "2.4rem",
    lineHeight: "3rem",
    fontWeight: 700,
    textAlign: "center",
    width: "100vw",
    height: "100vh",
    position: "absolute",
    paddingTop: "40vh",
  },
  ldsEllipsis: {
    display: "inline-block",
    position: "relative",
    width: "8rem",
    height: "8rem",
    "& div": {
      position: "absolute",
      top: "33px",
      width: "13px",
      height: "13px",
      borderRadius: "50%",
      background: "#fff",
      animationTimingFunction: "cubic-bezier(0, 1, 1, 0)",
    },
    "& div:nth-child(1)": {
      left: "8px",
      animation: "$lds-ellipsis1 0.6s infinite",
    },
    "& div:nth-child(2)": {
      left: "8px",
      animation: "$lds-ellipsis2 0.6s infinite",
    },
    "& div:nth-child(3)": {
      left: "32px",
      animation: "$lds-ellipsis2 0.6s infinite",
    },
    "& div:nth-child(4)": {
      left: "56px",
      animation: "$lds-ellipsis3 0.6s infinite",
    },
  },
  root: {
    background: "#0d1625",
    overflowX: "hidden",
    fontSize: "1.4rem",
    fontFamily: "Montserrat, sans-serif",
    color: "white",
  },
  iframeWrapper: {
    width: "80vw",
    height: "45vw",
    margin: "auto",
    "& iframe": {
      width: "100%",
      height: "100%",
    },
  },
  modalChildrenRegister: {},
  modalPaperRegister: {
    background: "#091E31",
    "& ul": {
      paddingLeft: "3rem",
      "& li": {
        fontWeight: 600,
      },
    },
  },
  titleModalRegister: {
    fontFamily: "Akira",
    fontSize: "3.6rem",
    fontWeight: 800,
    lineHeight: "41px",
    whiteSpace: "pre-wrap",
    [breakpoints.down(400)]: {
      fontSize: "3rem",
    },
  },
  detailsModalRegister: {
    fontWeight: 400,
    fontSize: "2rem",
    lineHeight: "29px",
    maxWidth: "100%",
    margin: "1rem 0",
  },
  boxSocialRegister: {
    display: "flex",
    gap: "2rem",
    margin: "2rem 0",
  },
  btnAccountPage: {
    width: "100%",
    background:
      "linear-gradient( 360deg, rgb(63,0,207) 0%, rgb(0,180,255) 100%)",
    border: "none",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    padding: "1rem",
  },
  sectionTop: {
    position: "relative",
    width: "100%",
    minHeight: "100vh",
    zIndex: 10,
    "& video": {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      position: "absolute",
      zIndex: "-1",
    },
  },
  wrapperTopWithMenu: {
    height: "100vh",
    minHeight: "80rem",
    display: "flex",
  },
  wrapperTop: {
    padding: "3rem 3rem 6rem 3rem",
    [breakpoints.up("sm")]: {
      paddingLeft: "13rem",
    },
    width: "100%",
    height: "100%",
  },
  contentTop: {
    zIndex: 0,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
    justifyContent: "space-around",
    [breakpoints.down("sm")]: {
      alignItems: "center",
    },
    "& h2": {
      fontFamily: "Akira",
      fontWeight: 600,
      textShadow: "0px 3px 9px rgba(0, 0, 0, 0.58)",
      lineHeight: 1.2,
      textTransform: "uppercase",
      whiteSpace: "pre-wrap",
      fontSize: "7rem",
      color: "white",
      [breakpoints.down("lg")]: {
        fontSize: "6rem",
      },
      [breakpoints.down("md")]: {
        fontSize: "4.8rem",
      },
      [breakpoints.down("sm")]: {
        fontSize: "3rem",
        textAlign: "center",
      },
    },
  },
  boxTextTop: {
    // height: "100%",
    display: "flex",
    justifyContent: "space-around",
    flexDirection: "column",
  },
  text: {
    fontSize: "1.8rem",
    lineHeight: 1.2,
    textAlign: "justify",
    fontWeight: 400,
    width: "100%",
    maxWidth: "80rem",
    marginTop: "2rem",
    color: "white",
    [breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  textMexcIntro: {
    maxWidth: "100%",
    textAlign: "left",
    fontWeight: 400,
  },
  boxExchanges: {
    [breakpoints.down("sm")]: {
      margin: "1rem 0",
    },
  },
  btnExchange: {
    border: "none",
    background: "none",
    cursor: "pointer",
    transition: "0.2s all ease-in-out",
    "& img": {
      width: "100%",
      height: "100%",
    },
    "&:hover": {
      opacity: 0.8,
    },
  },
  btnTrailer: {
    border: "none",
    marginBottom: "3rem",
    borderRadius: "1rem",
    transition: "0.2s all ease-in-out",
    background: `url(${bgHunter})`,
    backgroundSize: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "left",
    width: "100%",
    maxWidth: "40rem",
    minWidth: "30rem",
    height: "10rem",
    padding: "1rem",
    "& h3": {
      fontWeight: 600,
      fontSize: "1.8rem",
      textShadow: "0px 2px 2px rgba(0, 0, 0, 0.53)",
      color: "white",
    },
    "& h4": {
      fontWeight: 200,
      textTransform: "uppercase",
      color: "white",
      textShadow: "0px 2px 2px rgba(0, 0, 0, 0.53)",
    },
    "&:hover": {
      opacity: 0.8,
    },
  },
  wrapperIconPlay: {
    padding: "1rem",
    width: "max-content",
    display: "flex",
    cursor: "pointer",
    "& svg": {
      transition: "0.2s all ease-in-out",
    },
    transition: "0.2s all ease-in-out",
    "&:hover": {
      "& svg": {
        opacity: 0.8,
      },
    },
  },
  iconPlay: {
    fontSize: "3rem",
    filter:
      "brightness(0) invert(1) drop-shadow( 1px 1px 10px rgb(255, 255, 255))",
  },
  menu: {
    maxWidth: "10rem",
    minWidth: "10rem",
    padding: "6rem 0",
    position: "fixed",
    height: "100%",
    zIndex: 2,
    background: "transparent",
    [breakpoints.down("xs")]: {
      display: "none",
    },
  },
  bgMenu: {
    background: "#0d1625",
  },
  /*overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `url('/masque-bleu-trans.png')`,
    zIndex: "-1",
  },*/
  section: {
    [breakpoints.up("sm")]: {
      paddingLeft: "10rem",
    },
    background: palette.background.blueDark,
    "& h1": {
      fontSize: "4.5rem",
      textTransform: "uppercase",
      fontWeight: 700,
      color: "white",
      position: "relative",
      paddingLeft: "2rem",
      [breakpoints.down("sm")]: {
        paddingLeft: 0,
      },
      // top: "-0.5rem",
      // padding: "3rem 2rem",
      [breakpoints.down("xs")]: {
        fontSize: "2.8rem",
        top: "-1.5rem",
      },
    },
  },
  wrapperIntro: {
    padding: "3rem 2rem",
    minHeight: "100vh",
    position: "relative",
    [breakpoints.down("sm")]: {
      minHeight: "123rem",
    },
    [breakpoints.down(860)]: {
      minHeight: "1040px",
    },
    [breakpoints.down(650)]: {
      minHeight: "950px",
    },
    [breakpoints.down(550)]: {
      minHeight: "820px",
    },
    [breakpoints.down(500)]: {
      minHeight: "750px",
    },
    [breakpoints.down(450)]: {
      minHeight: "685px",
    },
    [breakpoints.down(400)]: {
      minHeight: "62rem",
    },
    "& h1": {
      position: "relative",
      zIndex: 1,
      textShadow: "2px 2px 5px #070c19",
    },
  },
  wrapperAccordionGame: {
    marginTop: "4rem",
    marginRight: "2rem",
    maxWidth: "66rem",
    marginLeft: "5rem",
    width: "100%",
    // margin: "6rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    [breakpoints.down("sm")]: {
      margin: "0 auto",
    },
  },
  boxIntroduction: {
    position: "relative",
    paddingTop: "2rem",
    paddingBottom: "3rem",
    paddingLeft: spacing(2),
    paddingRight: spacing(2),
    "& p": {
      fontSize: "1.8rem",
      lineHeight: 1.2,
      textAlign: "center",
      fontWeight: 400,
      whiteSpace: "pre-wrap",
      padding: "0 3rem",
    },
    "& a": {
      color: "white",
      textDecoration: "underline",
      transition: "0.2s all ease-in-out",
      fontWeight: 600,
      "&:hover": {
        opacity: 0.8,
      },
    },
  },
  imgGameMode: {
    width: "100%",
    height: "100%",
  },
  wrapperTitle: {
    marginBottom: "4rem",
    marginLeft: "2rem",
    marginRight: "2rem",
  },
  wrapperTitleWithoutMarginBottom: {
    marginBottom: 0,
  },
  bgTitle: {
    padding: "3rem 3rem 3rem 1rem",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    position: "relative",
    textAlign: "left",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0.3,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      zIndex: 0,
    },
    "& h1": {
      position: "relative",
      zIndex: 1,
    },
    [breakpoints.down("xs")]: {
      background: "none !important",
      "&::before": {
        background: "none !important",
      },
    },
  },
  bgTitleIntroduction: {
    // backgroundImage: `url(${titleIntroduction})`,
  },
  bgTitleEcosystem: {
    "&::before": {
      backgroundImage: `url(${titleEcosystem})`,
      opacity: 1,
    },
  },
  bgTitleLands: {
    "&::before": {
      backgroundImage: `url(${titleOurLands})`,
    },
  },
  bgTitleAvatars: {
    "&::before": {
      backgroundImage: `url(${titleAvatars})`,
    },
  },
  bgTitleGameAsset: {
    "&::before": {
      backgroundImage: `url(${titleGameAsset})`,
    },
  },
  bgTitleTeam: {
    "&::before": {
      backgroundImage: `url(${titleTeam})`,
    },
  },
  bgTitlePartners: {
    "&::before": {
      backgroundImage: `url(${titlePartners})`,
    },
  },
  bgTitleHunterProgram: {
    "&::before": {
      opacity: 1,
      backgroundImage: `url(${titleHunterProgram})`,
    },
  },
  overlayIntro: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: `url(${bgIntroduction})`,
    zIndex: 0,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    [breakpoints.down("sm")]: {
      background: `url(${bgIntroductionMobile})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      top: 0,
    },
    [breakpoints.down(740)]: {
      backgroundSize: "contain",
    },
  },
  overlayRTGDetails: {
    position: "relative",
    width: "100%",
    minHeight: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
    padding: "3rem 0",
  },

  wrapperRTGDetails: {
    width: "100%",
    maxWidth: "1400px",
    margin: "0 auto",
    position: "relative",
    zIndex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 2rem",
    [breakpoints.down("sm")]: {
      padding: "0 1rem",
    },
  },

  rtgDetailsContent: {
    display: "flex",
    flexDirection: "column",
    gap: "4rem",
    width: "100%",
    position: "relative",
    paddingBottom: "120px",
    [breakpoints.down("sm")]: {
      gap: "2rem",
      paddingBottom: "100px",
    },
  },

  rtgDetailsTitle: {
    fontFamily: "Akira",
    fontSize: "2rem",
    fontWeight: 600,
    whiteSpace: "pre-line",
  },

  boxRTGDetailsProcedural: {
    width: "100%",
    maxWidth: "450px",
    padding: "2rem",
    alignSelf: "flex-end",
    position: "relative",
    [breakpoints.down("xs")]: {
      display: "none",
    },
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "rgba(13, 22, 37, 0.8)",
      borderRadius: "8px",
      zIndex: -1,
    },
    "& h3": {
      fontSize: "3rem",
      fontWeight: "bold",
      marginBottom: "2rem",
      whiteSpace: "pre-line",
      position: "relative",
      zIndex: 1,
    },
    "& p": {
      fontSize: "1.6rem",
      whiteSpace: "pre-line",
      position: "relative",
      zIndex: 1,
    },
    [breakpoints.down("sm")]: {
      alignSelf: "center",
      margin: "0 auto",
    },
  },

  rtgDetailsLore: {
    width: "100%",
    maxWidth: "800px",
    margin: "2rem auto",
    fontFamily: "Akira",
    fontSize: "4rem",
    lineHeight: 1.5,
    textAlign: "center",
    color: "white",
    padding: "2rem",
    position: "relative",
    textShadow: "2px 2px 4px rgba(0, 0, 0, 0.9)",
    fontWeight: 500,
    zIndex: 2,
    transition: "opacity 0.3s ease-in-out",
    [breakpoints.down("sm")]: {
      padding: "1.5rem",
      margin: "1rem auto",
      fontSize: "3rem",
    },
  },

  boxRTGDetailsLoot: {
    width: "100%",
    maxWidth: "450px",
    padding: "2rem",
    alignSelf: "flex-start",
    position: "relative",
    marginRight: "auto",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "rgba(13, 22, 37, 0.8)",
      borderRadius: "8px",
      zIndex: -1,
    },
    "& h3": {
      fontSize: "3rem",
      fontWeight: "bold",
      marginBottom: "2rem",
      whiteSpace: "pre-line",
      position: "relative",
      zIndex: 1,
    },
    "& p": {
      fontSize: "1.6rem",
      whiteSpace: "pre-line",
      position: "relative",
      zIndex: 1,
    },
    [breakpoints.down("sm")]: {
      alignSelf: "center",
      margin: "0 auto",
    },
    [breakpoints.down("xs")]: {
      display: "none",
    },
  },

  btnLearnMore: {
    position: "absolute",
    bottom: "40px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 10,
    transition: "all 0.3s ease",
    "&:hover": {
      transform: "translateX(-50%) translateY(-2px) !important",
    },
    [breakpoints.down("sm")]: {
      bottom: "30px",
    },
  },

  travellingBackground: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "opacity 0.5s ease-in-out",
    zIndex: 0,
    "&::after": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "rgba(13, 22, 37, 0.4)",
      zIndex: 1,
    },
  },
  wrapperHunterProgram: {
    padding: "3rem 2rem",
    marginTop: "-4rem",
    // minHeight: "100vh",
    background: `url(${bgHunterProgram})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    [breakpoints.down("sm")]: {
      padding: "3rem 0",
    },
  },
  hunterProgramHeader: {
    width: "100%",
    display: "flex",
    marginBottom: "5px",
    marginLeft: "2rem",
    "& img": {
      width: "90%",
    },
  },
  titleAndLogoContainer: {
    width: "100%",
    position: "relative",
    marginBottom: "4rem",
    [breakpoints.down("sm")]: {
      marginBottom: "2rem",
    },
  },
  triangleLogoContainer: {
    position: "absolute",
    top: "35%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 1,
    "& img": {
      maxWidth: "250px",
      opacity: "0.5",
      [breakpoints.down("sm")]: {
        maxWidth: "200px",
      },
    },
  },
  posterImageDesktop: {
    width: "100%",
    height: "auto",
    boxShadow: "0 0 20px rgba(0,0,0,0.3)",
    display: "block",
    [breakpoints.down("sm")]: {
      display: "none",
    },
  },
  posterImageMobile: {
    width: "100%",
    height: "auto",
    boxShadow: "0 0 20px rgba(0,0,0,0.3)",
    display: "none",
    [breakpoints.down("sm")]: {
      display: "block",
    },
  },
  hunterProgramContent: {
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 2rem",
  },
  recruitmentPoster: {
    width: "100%",
    // maxWidth: "900px",
    margin: "0 auto",
  },
  startHuntingButton: {
    transition: "all 0.3s ease",
    marginTop: "3rem",
  },
  wrapperEcosystem: {
    padding: "3rem 2rem",
    background: `url(${bgEcosystem})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    minHeight: "100vh",
    "&::before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: "rgba(13, 22, 37, 0.2)",
      zIndex: 1,
    },
    "& > *": {
      position: "relative",
      zIndex: 2,
    },
  },

  ecosystemContent: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "1280px",
    width: "100%",
    margin: "0 auto",
    padding: "0 2rem",
    position: "relative",
    minHeight: "80vh",
    [breakpoints.down("sm")]: {
      justifyContent: "center",
      alignItems: "flex-end",
      gap: "2rem",
    },
    [breakpoints.down(850)]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },

  ecosystemColumn: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "2rem",
    flex: 1,
    maxWidth: "350px",
    zIndex: 2,
    [breakpoints.down(850)]: {
      maxWidth: "100%",
    },
  },

  ecosystemLogo: {
    height: "75px",
    transition: "transform 0.3s ease",
    filter: "drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.3))",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },

  ecosystemCharacter: {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    height: "auto",
    maxWidth: "none",
    zIndex: 1,
    [breakpoints.down("sm")]: {
      transform: "translate(-50%, -100%)",
    },
    [breakpoints.down(850)]: {
      display: "none",
    },
    /*breakpoints.down("md")]: {
      position: "relative",
      width: "100%",
      maxWidth: "500px",
      transform: "none",
      left: "auto",
      top: "auto",
    },*/
  },

  ecosystemBox: {
    width: "100%",
    padding: "2rem",
    "& p": {
      fontSize: "1.6rem",
      lineHeight: 1.5,
      color: "white",
      textAlign: "justify",
    },
    [breakpoints.down("sm")]: {
      padding: "1.5rem",
    },
  },
  gamingMechanism: {
    "& p": {
      whiteSpace: "pre-line",
    },
  },

  hqMechanism: {
    "& p": {
      whiteSpace: "pre-line",
    },
  },
  ecosystemBoxTitle: {
    fontSize: "1.8rem",
    fontWeight: "bold",
    marginBottom: "1.5rem",
    textTransform: "uppercase",
    textAlign: "left",
    color: "white",
  },
  wrapperGameAssets: {
    padding: "3rem 2rem",
    minHeight: "100vh",
    "& > *": {
      position: "relative",
      zIndex: 2,
    },
  },
  wrapperBoxGameAssets: {
    display: "flex",
    justifyContent: "center",
    gap: "4rem",
    paddingBottom: "4rem",
    flexWrap: "wrap",
    marginTop: "3rem",
    [breakpoints.down("sm")]: {
      gap: "3rem",
      marginTop: "0",
    },
  },
  tokenCardWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
  },
  tokenTitle: {
    fontSize: "3rem",
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
    fontFamily: "Akira",
  },
  tokenCard: {
    width: "300px",
    minHeight: "400px",
    borderRadius: "15px",
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    position: "relative",
    overflow: "hidden",
    "& img": {
      width: "200px",
      height: "200px",
      objectFit: "contain",
      marginBottom: spacing(2),
      position: "relative",
      zIndex: 2,
    },

    "& h4": {
      fontSize: "1.4rem",
      fontFamily: "Akira",
      fontWeight: 600,
      color: "white",
      position: "relative",
      zIndex: 2,
    },

    "& p": {
      fontSize: "1.4rem",
      lineHeight: 1.5,
      position: "relative",
      zIndex: 2,
      textAlign: "justify",
    },

    [breakpoints.down("sm")]: {
      width: "280px",
      minHeight: "380px",
    },
  },
  wrapperWeaponsRessources: {
    "& h4": {
      fontSize: "2rem",
      textAlign: "center",
      color: "white",
      zIndex: 2,
      fontWeight: 700,
    },
    "& p": {
      color: "white",
      fontSize: "1.4rem",
      textAlign: "center",
      maxWidth: "60rem",
      padding: "0 2rem",
      zIndex: 2,
    },
  },
  wrapperCarousselWPRS: {
    display: "flex",
    alignItems: "center",
    gap: "2rem",
    transition: "0.4s all ease-in-out",
    marginBottom: "3rem",
    height: "16rem",
    [breakpoints.down("xs")]: {
      gap: "0.5rem",
    },
  },
  boxWeaponResource: {
    border: "none",
    /*minWidth: "32rem",
    width: "32rem",
    minHeight: "17rem",
    height: "17rem",*/
    transition: "0.2s all ease-in-out",
    minWidth: "28rem",
    width: "28rem",
    minHeight: "12rem",
    height: "12rem",
    background: "transparent",
    backgroundImage: `url(${bgWrapperResource})`,
    backgroundPosition: "50%",
    backgroundRepeat: "no-repeat",
    pointerEvents: "all",
    filter: "blur(2px)",
    "& img": {
      width: "70%",
      padding: "2rem",
    },
  },
  boxWeaponResourceSelected: {
    filter: "blur(0px)",
    transition: "0.2s all ease-in-out",
    minWidth: "32rem",
    width: "32rem",
    minHeight: "14rem",
    height: "17rem",
    backgroundImage: `url(${bgWrapperResourceSelected})`,
    backgroundPosition: "70% 50%",
  },
  wrapperImgWPRSelected: {
    backgroundImage: `url(${bgWeaponResourceDetails})`,
    backgroundRepeat: "no-repeat",
    position: "relative",
    height: "833px",
    width: "1500px",
    backgroundSize: "contain",
    marginTop: "-10rem",
    "& img": {
      width: "40%",
      position: "absolute",
      /* width: 100px; */
      top: "35%",
      left: "30%",
    },
    [breakpoints.down("sm")]: {
      height: "600px",
      width: "1080px",
    },
    [breakpoints.down(650)]: {
      width: "800px",
      height: "450px",
      marginTop: "-5rem",
    },
    [breakpoints.down(500)]: {
      width: "560px",
      height: "315px",
      marginTop: "-5rem",
    },
  },
  wrapperImgResourceRock: {
    "& img": {
      top: "30%",
    },
  },
  wrapperLand: {
    padding: "3rem 2rem",
    [breakpoints.down(sizeMobileForOurLands)]: {
      padding: "3rem 0",
    },
  },
  wrapperContentLand: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    // minHeight: "32rem",
    // margin: "6rem",
  },
  textDetailsLands: {
    fontSize: "1.8rem",
    maxWidth: "77rem",
    textAlign: "center",
  },
  wrapperImgLands: {
    display: "flex",
    marginTop: "5rem",
    gap: "5rem",
  },
  btnImgLand: {
    transition: "0.5s all ease-in-out",
    background: "transparent",
    border: "none",
    cursor: "pointer",
    borderRadius: "50rem",
    filter: "blur(3px)",
    width: "10rem",
    height: "10rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  btnImgLandLuxion: {
    filter: "blur(3px)",
    width: "10rem",
    height: "10rem",
    boxShadow: "0px 0px 200px 20px rgba(199, 57, 37, .75)",
  },
  btnImgLandKryo: {
    boxShadow: "0px 0px 200px 20px rgba(38, 99, 153, 1)",
  },
  btnImgLandNaya: {
    filter: "blur(3px)",
    width: "10rem",
    height: "10rem",
    boxShadow: "0px 0px 200px 20px rgba(199, 134, 73, .75)",
  },
  btnImgLandSelected: {
    filter: "blur(0px)",
    width: "178px",
    height: "178px",
  },
  imgLand: {
    width: "140%",
    height: "105%",
  },
  wrapperBoxDetailsPlanet: {
    position: "relative",
    [breakpoints.down("sm")]: {
      top: "14rem",
    },
    [breakpoints.down(800)]: {
      top: "9rem",
    }, // 22rem
    [breakpoints.down(sizeMobileForOurLands)]: {
      top: "22rem",
    },
    [breakpoints.down(450)]: {
      top: "16rem",
    },
  },
  wrapperContentChara: {
    position: "relative",
  },
  wrapperImgChara: {
    /*backgroundImage: `url(${bgChara})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom",
    minHeight: "90rem",
    minWidth: "100vw",
    [breakpoints.down("sm")]: {
      backgroundImage: `url(${bgCharaMobile})`,
      minHeight: "90rem",
    },
    marginBottom: "-4rem",
    [breakpoints.down("sm")]: {
      marginTop: "-4rem",
    },*/
    "& img": {
      width: "100%",
    },
  },
  imgCharaDesktop: {
    display: "initial",
    [breakpoints.down("sm")]: {
      display: "none",
    },
  },
  imgCharaMobile: {
    display: "none",
    [breakpoints.down("sm")]: {
      display: "initial",
    },
  },
  readMoreLuxion: {
    marginTop: "1rem",
    marginLeft: "1.5rem",
    [breakpoints.down("sm")]: {
      display: "flex",
    },
    [breakpoints.up("sm")]: {
      display: "none",
    },
  },
  detailsLuxion: {
    marginTop: "1rem",
    marginLeft: "1.5rem",
    [breakpoints.down("sm")]: {
      display: "none",
    },
    [breakpoints.up("sm")]: {
      display: "block",
    },
  },
  wrapperBoxCharas: {
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    gap: "11rem",
    padding: "0 5rem 5rem 5rem",
    [breakpoints.down("md")]: {
      gap: "5rem",
    },
    [breakpoints.down("sm")]: {
      padding: "0 2rem 5rem 5rem",
      flexDirection: "column",
      gap: "5rem",
    },
  },
  boxChara: {
    [breakpoints.down("sm")]: {
      maxWidth: "36rem",
    },
  },
  btnBuyOpensea: {
    margin: "2rem auto",
  },
  textDetailsCharas: {
    marginTop: "2rem",
    marginLeft: "auto",
    marginRight: "auto",
    fontSize: "1.8rem",
    textAlign: "center",
    maxWidth: "90%",
  },
  wrapperPartners: {
    margin: "0 auto",
    padding: "3rem 2rem",
    width: "100%",
    textAlign: "center",
    "& img": {
      margin: "1.2rem 1.6rem",
      maxHeight: "6.4rem",
    },
  },
  btnRegular: {
    width: "auto",
    padding: "1rem",
    border: `1px solid ${palette.primary.light}`,
  },
  mAuto: {
    margin: "auto",
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0,
      transform: "translateY(10px)",
    },
    "100%": {
      opacity: 1,
      transform: "translateY(0)",
    },
  },

  "@keyframes fadeOut": {
    "0%": {
      opacity: 1,
      transform: "translateY(0)",
    },
    "100%": {
      opacity: 0,
      transform: "translateY(-10px)",
    },
  },

  fadeIn: {
    animation: "$fadeIn 0.3s ease-in-out forwards",
  },

  fadeOut: {
    animation: "$fadeOut 0.3s ease-in-out forwards",
  },
}));
