import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core";

export default function Footer() {
  const classes = useStyle();

  return (
    <footer className={classes.footer}>
      <div className={classes.title}>
        <p>
          Contract $OXYZ in Eth network:
          <span style={{ fontWeight: 600 }}>
            {" "}
            0xD0623da373F754C4B6762209EA77De59B21DD667
          </span>
        </p>
        <p>
          Contract $OXYZ in Immutable zkEVM network:
          <span style={{ fontWeight: 600 }}>
            {" "}
            0x5178fb04a0F469C7d309144B439640c9c1EBa11c
          </span>
        </p>
        <p style={{ marginTop: "1rem" }}>
          {" "}
          OXYA ORIGIN IS A REGISTERED TRADEMARK ALL RIGHTS RESERVED. OG
        </p>
        <p style={{ marginTop: "1rem" }}>
          ALL LOGOS ARE REGISTERED TRADEMARKS OF THEIR RESPECTIVE OWNERS. ALL
          CONTENTS OF THIS DOCUMENT, UNLESS OTHERWISE CREDITED, ARE COPYRIGHT ©
          2023
        </p>
      </div>

      <div className={classes.wrapperContent}>
        <div>
          <strong>Resources</strong>
          <a
            href="https://drive.google.com/drive/folders/16O1akt2qa7zVQpiAbpxubeKIdIZCfNd6"
            target="_blank"
            rel="noreferrer"
          >
            Media Kit
          </a>
          <a
            href="https://oxya-origin.gitbook.io/oxya-origin/"
            target="_blank"
            rel="noreferrer"
          >
            Whitepaper
          </a>
          <a href="https://app.oxyaorigin.com">Pre-Alpha</a>
        </div>
        <div>
          <strong>Company</strong>
          <Link to="/terms">Terms</Link>
          <Link to="/terms/rtg">Terms Road To Genesis</Link>
          <Link to="/privacy-policy">Privacy Policy</Link>
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSddpXHHX3b4Lqx88uaItMyEswIY7pBYawwVlKgHg1pgAztIzg/viewform"
            target="_blank"
            rel="noreferrer"
          >
            Partnership
          </a>
          contact@oxyaorigin.com
        </div>
        <div>
          <strong>Social</strong>
          <a
            href="https://discord.com/invite/oxyaorigin"
            target="_blank"
            rel="noreferrer"
          >
            Discord
          </a>
          <a
            href="https://twitter.com/OxyaOrigin"
            target="_blank"
            rel="noreferrer"
          >
            Twitter
          </a>
          <a
            href="https://www.youtube.com/@oxyaorigin2813"
            target="_blank"
            rel="noreferrer"
          >
            Youtube
          </a>
          <a
            href="https://linktr.ee/Oxyaorigin"
            target="_blank"
            rel="noreferrer"
          >
            All Links
          </a>
        </div>
      </div>
    </footer>
  );
}

const useStyle = makeStyles(({ breakpoints }) => ({
  footer: {
    paddingLeft: "10rem",
    margin: "4rem auto",
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [breakpoints.down("sm")]: {
      paddingLeft: 0,
    },
  },
  title: {
    fontSize: "1.2rem",
    textTransform: "uppercase",
    color: "white",
    textAlign: "center",
  },
  wrapperContent: {
    marginTop: "2rem",
    display: "flex",
    fontSize: "0.75rem",
    lineHeight: "1.2rem",
    color: "white",
    gap: "1.25rem",
    justifyContent: "space-evenly",
    "& div": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      fontSize: "1rem",
      "& strong": {
        fontSize: "1.4rem",
        marginBottom: "0.5rem",
      },
      "& a": {
        color: "white",
        textDecoration: "none",
        transition: "0.2s opacity ease-in-out",
        "&:hover": {
          opacity: 0.8,
        },
      },
    },
  },
}));
